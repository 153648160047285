<template>
  <!-- 审批模板 -->
  <approval-form
    :title="formTitle"
    :open="open"
    :approvalRecord="approvalRecord"
    :billImageUrl="form.billImageUrl"
    :action="action"
    @onClose="onClose"
    @ok="handleOk"
  >
    <!-- 审批头部内容 -->
    <div slot="approvalHeader">
      <a-row>
        <a-col :span="6"> </a-col>
        <a-col :span="6">
          审批编号:
        </a-col>
        <a-col :span="6">
          {{ form.approvalNo }}
        </a-col>
        <a-col :span="6"> </a-col>
      </a-row>
      <a-row>
        <a-col :span="6"> </a-col>
        <a-col :span="6">
          申请人:
        </a-col>
        <a-col :span="6">
          {{ form.applicantName }}
        </a-col>
        <a-col :span="6"> </a-col>
      </a-row>
      <a-row>
        <a-col :span="6"> </a-col>
        <a-col :span="6">
          申请时间:
        </a-col>
        <a-col :span="6">
          {{ form.createTime }}
        </a-col>
        <a-col :span="6"> </a-col>
      </a-row>
      <a-row>
        <a-col :span="6"> </a-col>
        <a-col :span="6">
          费用类型:
        </a-col>
        <a-col :span="6">
          {{ form.expenseTypeName }}
        </a-col>
        <a-col :span="6"> </a-col>
      </a-row>
      <a-row>
        <a-col :span="6"> </a-col>
        <a-col :span="6">
          申请金额:
        </a-col>
        <a-col :span="6">
          {{ form.applyAmount }}
        </a-col>
        <a-col :span="6"> </a-col>
      </a-row>
      <a-row>
        <a-col :span="6"> </a-col>
        <a-col :span="6">
          月费用加总:
        </a-col>
        <a-col :span="6">
          {{ form.expenseTotal }}
        </a-col>
        <a-col :span="6"> </a-col>
      </a-row>
      <a-row>
        <a-col :span="6"> </a-col>
        <a-col :span="6">
          所属项目:
        </a-col>
        <a-col :span="6">
          {{ form.deptName }}
        </a-col>
        <a-col :span="6"> </a-col>
      </a-row>
      <a-row>
        <a-col :span="6"> </a-col>
        <a-col :span="6">
          交易对手方:
        </a-col>
        <a-col :span="6">
          {{ form.counterparty }}
        </a-col>
        <a-col :span="6"> </a-col>
      </a-row>
      <a-row>
        <a-col :span="6"> </a-col>
        <a-col :span="6">
          付款方式:
        </a-col>
        <a-col :span="6">
          {{ form.paymentTypeName }}
        </a-col>
        <a-col :span="6"> </a-col>
      </a-row>
      <a-row v-if="form.actualPayer">
        <a-col :span="6"> </a-col>
        <a-col :span="6">
          实际付款人:
        </a-col>
        <a-col :span="6">
          {{ form.actualPayer }}
        </a-col>
        <a-col :span="6"> </a-col>
      </a-row>
      <a-row v-if="form.remark">
        <a-col :span="6"> </a-col>
        <a-col :span="6">
          备注说明:
        </a-col>
        <a-col :span="6">
          {{ form.remark }}
        </a-col>
        <a-col :span="6"> </a-col>
      </a-row>
    </div>
    <!-- 审批明细内容 -->
    <div slot="aprrovalDetail">
      <a-card title="采购明细" class="expense-detail">
        <div class="expense-content-title">
          <span class="left"></span>
          <span class="expense-col">采购内容</span>
          <span class="expense-col">数量</span>
          <span class="expense-col">单位</span>
          <span class="expense-col">单价</span>
          <span class="expense-col">总价</span>
          <span class="right"></span>
        </div>
        <div class="expense-content-item" v-for="(item, index) in form.purchaseList" :key="index">
          <a-form-model-item class="left"> {{ index + 1 }}</a-form-model-item>
          <a-form-model-item class="expense-col"><span class="first-col" :title="item.content"> {{ item.content }}</span></a-form-model-item>
          <a-form-model-item class="expense-col"> {{ item.quantity }}</a-form-model-item>
          <a-form-model-item class="expense-col"> {{ item.unit }}</a-form-model-item>
          <a-form-model-item class="expense-col"> {{ item.price }}</a-form-model-item>
          <a-form-model-item class="expense-col">{{ item.amount }} </a-form-model-item>
        </div>
      </a-card>
    </div>
  </approval-form>
</template>
<script>
import { getExpenses } from '@/api/iot/expenses'
import ApprovalForm from '../ApprovalForm'
export default {
  name: 'ExpenseDetail',
  components: {
    ApprovalForm
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    approvalRecord: {
      type: Object,
      default: null
    },
    action: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      open: this.isShow,
      form: {},
      applicantName: '',
      expenseTypeOptions: [],
      paymentTypeOptions: []
    }
  },
  computed: {
    formTitle() {
      if (this.action === 'reject') {
        return '费用申请撤回'
      } else if (this.action === 'approval') {
        return '费用审批'
      } else {
        return '费用详情'
      }
    }
  },
  watch: {},
  mounted() {
    console.log('action', this.action)
    this.getData()
  },
  methods: {
    onClose() {
      this.open = false
      this.$emit('componentClose')
    },
    handleOk() {
      this.$emit('ok')
    },
    async getData() {
      await this.getDictData()
      this.getExpensesData(this.approvalRecord)
    },
    // 获取字典数据
    async getDictData() {
      await this.getDicts('iot_expense_type').then(response => {
        this.expenseTypeOptions = response.data
      })
      await this.getDicts('sys_pay_type').then((response) => {
        this.paymentTypeOptions = response.data
      })
    },

    getExpensesData(record) {
      console.log('record', record)
      this.form.applicantName = record.promoter
      /** 获取费用信息 */
      getExpenses(record.approvalKindId).then(response => {
        this.form = response.data
        // 费用类型名称
        this.form.expenseTypeName = this.selectDictLabel(this.expenseTypeOptions, this.form.expenseType)
        this.form.paymentTypeName = this.selectDictLabel(this.paymentTypeOptions, this.form.paymentType)
        console.log('billImageUrl', this.form.billImageUrl)
        this.form.purchaseList.forEach(p => {
          p.amount = (p.quantity * 1 * p.price * 1).toFixed(2)
        })
      })
      this.open = true
    }
  }
}
</script>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "approval-form",
    {
      attrs: {
        title: _vm.formTitle,
        open: _vm.open,
        approvalRecord: _vm.approvalRecord,
        billImageUrl: _vm.form.billImageUrl,
        action: _vm.action,
      },
      on: { onClose: _vm.onClose, ok: _vm.handleOk },
    },
    [
      _c(
        "div",
        { attrs: { slot: "approvalHeader" }, slot: "approvalHeader" },
        [
          _c(
            "a-row",
            [
              _c("a-col", { attrs: { span: 6 } }),
              _c("a-col", { attrs: { span: 6 } }, [_vm._v(" 审批编号: ")]),
              _c("a-col", { attrs: { span: 6 } }, [
                _vm._v(" " + _vm._s(_vm.form.approvalNo) + " "),
              ]),
              _c("a-col", { attrs: { span: 6 } }),
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c("a-col", { attrs: { span: 6 } }),
              _c("a-col", { attrs: { span: 6 } }, [_vm._v(" 申请人: ")]),
              _c("a-col", { attrs: { span: 6 } }, [
                _vm._v(" " + _vm._s(_vm.form.applicantName) + " "),
              ]),
              _c("a-col", { attrs: { span: 6 } }),
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c("a-col", { attrs: { span: 6 } }),
              _c("a-col", { attrs: { span: 6 } }, [_vm._v(" 申请时间: ")]),
              _c("a-col", { attrs: { span: 6 } }, [
                _vm._v(" " + _vm._s(_vm.form.createTime) + " "),
              ]),
              _c("a-col", { attrs: { span: 6 } }),
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c("a-col", { attrs: { span: 6 } }),
              _c("a-col", { attrs: { span: 6 } }, [_vm._v(" 费用类型: ")]),
              _c("a-col", { attrs: { span: 6 } }, [
                _vm._v(" " + _vm._s(_vm.form.expenseTypeName) + " "),
              ]),
              _c("a-col", { attrs: { span: 6 } }),
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c("a-col", { attrs: { span: 6 } }),
              _c("a-col", { attrs: { span: 6 } }, [_vm._v(" 申请金额: ")]),
              _c("a-col", { attrs: { span: 6 } }, [
                _vm._v(" " + _vm._s(_vm.form.applyAmount) + " "),
              ]),
              _c("a-col", { attrs: { span: 6 } }),
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c("a-col", { attrs: { span: 6 } }),
              _c("a-col", { attrs: { span: 6 } }, [_vm._v(" 月费用加总: ")]),
              _c("a-col", { attrs: { span: 6 } }, [
                _vm._v(" " + _vm._s(_vm.form.expenseTotal) + " "),
              ]),
              _c("a-col", { attrs: { span: 6 } }),
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c("a-col", { attrs: { span: 6 } }),
              _c("a-col", { attrs: { span: 6 } }, [_vm._v(" 所属项目: ")]),
              _c("a-col", { attrs: { span: 6 } }, [
                _vm._v(" " + _vm._s(_vm.form.deptName) + " "),
              ]),
              _c("a-col", { attrs: { span: 6 } }),
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c("a-col", { attrs: { span: 6 } }),
              _c("a-col", { attrs: { span: 6 } }, [_vm._v(" 交易对手方: ")]),
              _c("a-col", { attrs: { span: 6 } }, [
                _vm._v(" " + _vm._s(_vm.form.counterparty) + " "),
              ]),
              _c("a-col", { attrs: { span: 6 } }),
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c("a-col", { attrs: { span: 6 } }),
              _c("a-col", { attrs: { span: 6 } }, [_vm._v(" 付款方式: ")]),
              _c("a-col", { attrs: { span: 6 } }, [
                _vm._v(" " + _vm._s(_vm.form.paymentTypeName) + " "),
              ]),
              _c("a-col", { attrs: { span: 6 } }),
            ],
            1
          ),
          _vm.form.actualPayer
            ? _c(
                "a-row",
                [
                  _c("a-col", { attrs: { span: 6 } }),
                  _c("a-col", { attrs: { span: 6 } }, [
                    _vm._v(" 实际付款人: "),
                  ]),
                  _c("a-col", { attrs: { span: 6 } }, [
                    _vm._v(" " + _vm._s(_vm.form.actualPayer) + " "),
                  ]),
                  _c("a-col", { attrs: { span: 6 } }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.remark
            ? _c(
                "a-row",
                [
                  _c("a-col", { attrs: { span: 6 } }),
                  _c("a-col", { attrs: { span: 6 } }, [_vm._v(" 备注说明: ")]),
                  _c("a-col", { attrs: { span: 6 } }, [
                    _vm._v(" " + _vm._s(_vm.form.remark) + " "),
                  ]),
                  _c("a-col", { attrs: { span: 6 } }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "aprrovalDetail" }, slot: "aprrovalDetail" },
        [
          _c(
            "a-card",
            { staticClass: "expense-detail", attrs: { title: "采购明细" } },
            [
              _c("div", { staticClass: "expense-content-title" }, [
                _c("span", { staticClass: "left" }),
                _c("span", { staticClass: "expense-col" }, [
                  _vm._v("采购内容"),
                ]),
                _c("span", { staticClass: "expense-col" }, [_vm._v("数量")]),
                _c("span", { staticClass: "expense-col" }, [_vm._v("单位")]),
                _c("span", { staticClass: "expense-col" }, [_vm._v("单价")]),
                _c("span", { staticClass: "expense-col" }, [_vm._v("总价")]),
                _c("span", { staticClass: "right" }),
              ]),
              _vm._l(_vm.form.purchaseList, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "expense-content-item" },
                  [
                    _c("a-form-model-item", { staticClass: "left" }, [
                      _vm._v(" " + _vm._s(index + 1)),
                    ]),
                    _c("a-form-model-item", { staticClass: "expense-col" }, [
                      _c(
                        "span",
                        {
                          staticClass: "first-col",
                          attrs: { title: item.content },
                        },
                        [_vm._v(" " + _vm._s(item.content))]
                      ),
                    ]),
                    _c("a-form-model-item", { staticClass: "expense-col" }, [
                      _vm._v(" " + _vm._s(item.quantity)),
                    ]),
                    _c("a-form-model-item", { staticClass: "expense-col" }, [
                      _vm._v(" " + _vm._s(item.unit)),
                    ]),
                    _c("a-form-model-item", { staticClass: "expense-col" }, [
                      _vm._v(" " + _vm._s(item.price)),
                    ]),
                    _c("a-form-model-item", { staticClass: "expense-col" }, [
                      _vm._v(_vm._s(item.amount) + " "),
                    ]),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }